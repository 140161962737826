.text-field-instance {
    height: 14px;
    width: 7px;
    position: relative;
    object-fit: contain;
}

.back {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-top: -3px;
}

.backbtn {
    width: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.what-is-the {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 500;
}

.a-brief-description {
    width: 100%;
    position: relative;
    font-size: var(--body-largemed16-size);
    line-height: 24px;
    font-weight: 500;
    font-family: var(--body-largemed16);
    color: var(--priism-primaryblue);
    display: inline-block;
    max-width: 100%;
}

.content-frame,
.state-layer1,
.text-field1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content-frame {
    align-self: stretch;
    flex-direction: column;
    gap: 4px;
    max-width: 100%;
    font-size: var(--title-largemed22-size);
    color: var(--priism-primarydarkgrey);
}

.state-layer1,
.text-field1 {
    box-sizing: border-box;
}

.state-layer1 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    align-self: stretch;
    height: 56px;
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-base);
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-primarydarkgrey);
    min-width: 250px;
}

.text-field1 {
    width: 450px;
    border-radius: var(--br-9xs);
    /* border: 1px solid var(--priism-primaryltgrey); */
    flex-direction: column;
}

.supporting-text1 {
    width: 178px;
    position: relative;
    line-height: 18px;
    display: none;
}

.supporting-text,
.text-field {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.supporting-text {
    width: 100%;
    height: 20px;
    margin: 0 !important;
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0;
    box-sizing: border-box;
}

.text-field {
    /* width: 450px; */
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
    margin-bottom: -20px;
    padding-bottom: var(--padding-xl);
}

.titleofstudy-inputfields,
.text-field-wrapper,
.username {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.titleofstudy-inputfields,
.username {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
}

.titleofstudy-inputfields {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: var(--padding-5xl);
    font-size: var(--body-small12-size);
    color: var(--priism-primaryltgrey);
    margin-top: 1%;
}

.iconplus {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button-text2 {
    position: relative;
    font-size: var(--label-large14-size);
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
    font-family: var(--body-small12);
    color: var(--priism-secondarywhite);
    text-align: left;
}

.primaryfilledbuttons,
.primaryoutlinedbuttons,
.primaryplainbuttons {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xs);
    background-color: var(--priism-primaryblue);
    height: 40px;
    border-radius: var(--br-5xs);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: var(--gap-5xs);
}

.primaryfilledbuttons {
    background-color: var(--priism-primaryblue) !important;
}

.primaryfilledbuttons,
.primaryoutlinedbuttons,
.primaryplainbuttons {
    border-radius: var(--br-5xs) !important;
    color: var(--priism-secondarywhite) !important;
    height: 40px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    padding: var(--padding-5xs) !important;
    box-sizing: border-box !important;
    font-size: var(--label-large14-size) !important;
}

.primaryoutlinedbuttons,
.primaryplainbuttons {
    color: var(--priism-primaryblue) !important;
}

.primaryoutlinedbuttons {
    border: 1px solid var(--priism-primaryblue) !important;
    gap: 16px;
}

.back-button-frame {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-base) var(--padding-5xs);
    gap: 16px;
}

.title-frame {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
}

.title-frame {
    flex: 1;
    flex-direction: column;
    /*padding: var(--padding-21xl) 0;
    gap: 16px;*/
    position: relative;
    top: 25px;
    height: 90%;
    max-width: 100%;
    font-size: var(--body-largemed16-size);
    color: var(--priism-primaryblue);
    margin: 0% 3%;
    margin-bottom: 100px;
}

@media screen and (max-width: 825px) {
    .title-frame {
        max-width: 100%;
    }
}

@media screen and (max-width: 675px) {
    .titleofstudy-inputfields {
        gap: var(--gap-13xl);
    }
}

@media screen and (max-width: 450px) {
    .what-is-the {
        font-size: var(--headline-smallsb18-size);
        line-height: 22px;
    }

    .text-field {
        max-width: 100%;
    }

    .title-frame {
        padding-top: var(--padding-7xl);
        padding-bottom: var(--padding-7xl);
        box-sizing: border-box;
    }
}