.sign-up {
    margin: 0;
    position: relative;
    font-size: var(--display-small-36-size);
    line-height: 2.75rem;
    font-weight: 400;
    font-family: var(--body-medium14);
    color: var(--color-black);
    text-align: left;
    white-space: nowrap;
}

.inputfields,
.signintxt,
.signuptxt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.signintxt {
    flex: 1;
    padding: var(--padding-5xs);
    box-sizing: border-box;
}

.inputfields,
.signuptxt {
    align-self: stretch;
}

.inputfields {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-base);
}

.mainbuttons {
    align-self: stretch;
    height: 2.5rem;
}

.already-have-an {
    color: var(--color-darkslategray);
}

.sign-in {
    color: var(--color-cornflowerblue);
}

.already-have-an-container {
    width: 16.13rem;
    position: relative;
    font-size: var(--body-medium14-size);
    line-height: 1.13rem;
    font-family: var(--body-medium14);
    text-align: left;
    display: inline-block;
    flex-shrink: 0;
}

.noaccount,
.signupbox {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}

.signupbox {
    margin: 0;
    border-radius: var(--br-xs);
    background-color: #fffffd;
    box-shadow: 0 4px 35px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
    gap: var(--gap-xl);
    min-height: 46.13rem;
    max-width: 100%;
}

@media screen and (max-width: 1050px) {
    .sign-up {
        font-size: var(--font-size-10xl);
        line-height: 2.19rem;
    }
}

@media screen and (max-width: 750px) {
    .signuptxt {
        gap: var(--gap-45xl);
    }
}

@media screen and (max-width: 450px) {
    .sign-up {
        font-size: var(--font-size-3xl);
        line-height: 1.63rem;
    }

    .signuptxt {
        gap: var(--gap-45xl);
    }
}