.priism {
  position: relative;
  letter-spacing: -0.02em;
  white-space: nowrap;
}

.frame-child {
  height: 13.4px;
  width: 21.3px;
  position: relative;
  z-index: 1;
  margin-left: -25px;
  top: -10px;
}

.priism-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profilephoto-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}

.researcher,
.vivek-shastri {
  align-self: stretch;
  position: relative;
}

.vivek-shastri {
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.researcher {
  height: 16px;
}

.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.arrow-icon {
  height: 6px;
  width: 12px;
  position: relative;
}

.logoheader,
.profile-card {
  display: flex;
  flex-direction: row;
}

.profile-card {
  width: 300px;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--label-large14-size);
  font-family: var(--headline-smallsb18);
  cursor: pointer;
}

.logoheader {
  align-self: stretch;
  /* background: linear-gradient(to right, #433753 0%, 44.54314708709717%, #853848 89.08629417419434%, 94.54314708709717%, #363756 100%); */
  background: linear-gradient(to right, rgba(67, 55, 83, 1) 0%, 44.54314708709717%, rgba(133, 56, 72, 1) 89.08629417419434%, 94.54314708709717%, rgba(54, 55, 86, 1) 100%);
  ;
  overflow: hidden;
  /* align-items: flex-end; */
  justify-content: space-between;
  padding: 0px 15px 0px 5px;
  gap: var(--gap-xl);
  top: 0;
  z-index: 99;
  position: sticky;
  text-align: left;
  font-size: var(--font-size-13xl-1);
  color: var(--priism-secondarywhite);
  font-family: var(--font-josefin-sans);
}

.menuitem {
  padding: 10px 14px !important;
}

.logout_menu {
  color: var(--priism-primaryblue) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 100% !important;
}

@media screen and (max-width: 675px) {
  .logoheader {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-7xl);
    box-sizing: border-box;
  }
}

.animate-negative-points {
  width: 25px;
  height: 25px;
  background-color: orange;
  font-weight: bold;
  position: relative;
  animation: negative-points;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes negative-points {
  from {background-color: red;}
  to {background-color: orange;}
}

.animate-positive-points {
  width: auto;
  height: auto;
  background-color: green;
  font-weight: bold;
  position: relative;
  animation: positive-points;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
}

@keyframes positive-points {
  from {background-color: lightgreen;}
  to {background-color: green;}
}