.username1 {
    flex: 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.email-address {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.priisminputfield,
.username {
    align-self: stretch;
    font-family: var(--body-medium14);
}

.priisminputfield {
    border: 0;
    background-color: transparent;
    height: 2.5rem;
    font-size: var(--font-size-xs);
    color: var(--color-gray-100);
}

.username {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    gap: var(--gap-xs);
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--color-black);
}