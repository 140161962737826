.enter-your-password {
    flex: 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.alreadyhavean-account {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    flex-shrink: 0;
}

.passwordfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-medium14);
    font-size: var(--font-size-xs);
    color: var(--color-gray-100);
}

.button-text,
.usernameoremailaddress {
    align-self: stretch;
    height: 1.25rem;
}

.button-text {
    height: 4.94rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    gap: var(--gap-xs);
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--color-black);
    font-family: var(--body-medium14);
}