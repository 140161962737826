.signup-leftgrfx-icon {
    min-height: 100% !important;
    height: 100% !important;
}

.mobile_logo_frame {
    display: none;
}

.logo-1-icon {
    width: 7.56rem;
    height: 2.31rem;
    position: relative;
    object-fit: cover;
}

.logo-frame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1.5rem;
}

.sign-up-box {
    flex: 0.8778;
    flex-direction: column;
    padding: 6.25rem 2.75rem 0.75rem;
    box-sizing: border-box;
    gap: var(--gap-70xl);
    min-width: 31.25rem;
    max-width: 100%;
}

.frame-left-graphic,
.sign-up-box,
.sign-up-desktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-left-graphic {
    align-self: stretch;
    flex-direction: row;
    row-gap: 20px;
    max-width: 100%;
}

.sign-up-desktop {
    width: 100%;
    position: relative;
    background-color: #fffffd;
    overflow: hidden;
    flex-direction: column;
    letter-spacing: normal;
}

@media screen and (max-width: 1125px) {

    .leftgrfx-icon,
    .sign-up-box {
        flex: 1;
    }

    .frame-left-graphic {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1050px) {
    .sign-up-box {
        padding-top: 4.06rem;
        padding-bottom: 1.25rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 750px) {

    .leftgrfx-icon,
    .sign-up-box {
        min-width: 100%;
    }

    .sign-up-box {
        gap: var(--gap-70xl);
        padding-left: var(--padding-3xl);
        padding-right: var(--padding-3xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 900px) {
    .leftgrfx-icon {
        display: none;
    }

    .mobile_logo_frame {
        display: block;
        width: 100%;
        object-fit: contain;
    }

    .sign-up-desktop {
        height: 100%;
    }

    .css-0 {
        height: inherit !important;
    }

    .sign-up-box {
        gap: var(--gap-70xl);
        padding-top: 2.63rem;
        box-sizing: border-box;
    }
}

.logo-1-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-5xl);
}

.logo-1-icon2 {
    width: 121px;
    position: absolute;
    bottom: 25px;
    height: 37px;
    object-fit: cover;
}