.variable-name-retinal-artery-parent {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: var(--card-elevation);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-mini);
    box-sizing: border-box;
    gap: var(--gap-base);
    min-width: 365px;
    max-width: 400px;
}

.unassigned-variables {
    width: 181px;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    text-align: left;
    color: rgba(3, 3, 3, 0.651);
}

.variable-name-retinal-artery-container {
    align-self: stretch;
    position: relative;
    line-height: 24px;
}

.secondaryoutlinedbuttons-wrapper {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--m3-body-medium-size);
    color: var(--priism-primaryblue);
    font-family: var(--display-small-36);
}

.variable-name {
    font-weight: 500;
}

.span1 {
    font-size: var(--headline-smallsb18-size);
    font-weight: 600;
    font-family: var(--display-small-36);
}

.secondaryoutlinedbuttons {
    border-radius: var(--br-5xs);
    border: 1px solid var(--priism-primaryltgrey);
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-xs) var(--padding-5xs) 0px;
}

.icontrashplain {
    width: 40px;
    border-radius: var(--br-81xl);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
}

.state-layer43 {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}


.icon10 {
    position: relative;
    width: 24px;
    height: 24px;
}

.button-text6 {
    position: relative;
    font-weight: 600;
}

.testlist-cards3 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    font-family: var(--m3-body-medium);
}