.my-list-of {
  width: 497.1px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: bold;
  display: inline-block;
  font-size: large;
  font-family: "Inter" !important;
}

.testlist-cards {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 36px;
}

.my-list-of-test-research-parent {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: "Inter" !important;
  /* padding: 40px 32px 36px; */
  /* gap: 20px; */
  /* padding-bottom: 10% !important; */
}

/* .my-list-of-test-research-parent {} */

.right-content-space {
  background-image: linear-gradient(to right, transparent, white);
}