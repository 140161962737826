.blue-bordered-layout {
    width: fit-content;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    border: 1px solid var(--priism-primaryblue);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base);
    max-width: 100%;
    font-size: var(--label-large14-size);
    font-family: var(--title-largemed22);
}

.full-width {
    width: 100% !important;
}

.white-card-layout-with-shadow {
    width: fit-content;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-xl);
    box-sizing: border-box;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    font-size: var(--body-small12-size);
    color: var(--color-black);
    margin-top: 1%;
}

.sub-head-level-1 {
    margin: 0;
    width: fit-content;
    position: relative;
    font-size: var(--headline-smallsb18-size);
    line-height: 24px;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    max-width: 100%;
    color: var(--priism-primarydarkgrey);
}

.checkboxes {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px var(--padding-xs) 0px var(--padding-2xs);
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: var(--title-largemed22-size);
    color: var(--priism-primarydarkgrey);
    font-family: var(--title-largemed22);
}

.content_section {
    width: 60%;
}

.mainContent {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cross_sectional_longitudinal_section {
    display: flex;
    align-items: center;
    padding: 0;
}

.card-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: stretch;
    gap: var(--gap-11xl);
    max-width: 100%;
    font-size: var(--body-small12-size);
    font-family: var(--title-largemed22);
}

.desc_info_icon {
    cursor: pointer;
    color: #ff8800;
}

.optional_box_container {
    display: flex !important;
    flex-direction: row !important;
    border: 1px solid var(--priism-primaryblue);
    padding: 2%;
    width: fit-content;
    border-radius: 10px;
    justify-content: center;
    /* margin-left: 5%;
    margin-right: 5%; */
    margin: auto;
}

.optional_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 345px;
    height: 205px;
    margin: 1%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    text-align: left;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.optional_box_selected {
    border-color: #D04D8C;
    cursor: pointer;
}

.optional_box_img {
    width: 85px;
    height: 84px;
    margin: 5%;
}