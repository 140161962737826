.leftgrfx-icon {
    height: 100vh !important;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    min-width: 31.25rem;
    min-height: 100vh !important;
}

.mobile_logo_frame {
    display: none;
}

.logo-1-icon {
    width: 7.56rem;
    height: 2.31rem;
    position: relative;
    object-fit: cover;
}

.top-header-frame {
    align-self: stretch;
    display: inline-block;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    /* padding: var(--padding-5xl); */
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.privacy-text{
    position: absolute;
    bottom: 0px;
    left: 50%;
}

.signin-main {
    flex: 0.8687;
    flex-direction: column;
    /* padding: 100px 44px var(--padding-xs); */
    height: 100vh;
    background: antiquewhite;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 15%;
    box-sizing: border-box;
    gap: 8vh;
    min-width: 500px;
    max-width: 100%;
}

.key_feature_section {
    padding: 3%;
}

.left-graphic-frame,
.sign-indesktop,
.signin-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.left-graphic-frame {
    align-self: stretch;
    flex-direction: row;
    row-gap: 20px;
    max-width: 100%;
}

.sign-indesktop {
    width: 100%;
    position: relative;
    background-color: #fffffd;
    overflow: hidden;
    flex-direction: column;
    letter-spacing: normal;
}

@media screen and (max-width: 1125px) {

    .leftgrfx-icon,
    .signin-main {
        flex: 1;
    }

    .left-graphic-frame {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1050px) {
    .signin-main {
        padding-top: 4.06rem;
        padding-bottom: 1.25rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 750px) {

    .leftgrfx-icon,
    .signin-main {
        min-width: 100%;
    }

    .signin-main {
        /* gap: var(--gap-70xl); */
        padding-left: var(--padding-3xl);
        padding-right: var(--padding-3xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 950px) {
    .leftgrfx-icon {
        display: none;
    }

    .mobile_logo_frame {
        display: block;
        width: 100%;
        object-fit: contain;
    }

    .sign-indesktop {
        height: 100%;
    }

    .css-0 {
        height: inherit !important;
    }

    .signin-main {
        /* gap: var(--gap-70xl); */
        padding-top: 2.63rem;
        box-sizing: border-box;
    }
}