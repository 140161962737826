.research-module {
  flex: 1;
  position: relative;
  line-height: 32px;
  font-weight: 600;
}

.study-list-section {
  width: 238px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-smi);
  box-sizing: border-box;
  font-size: var(--headline-mediumsemibold24-size);
  color: var(--color-cornflowerblue);
}

.list-icon,
.study-list {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
}

.list-icon {
  height: 24.6px;
  width: 24.6px;
  overflow: hidden;
}

.study-list {
  flex: 1;
  font-size: var(--headline-smallsb18-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--headline-smallsb18);
  color: var(--priism-primarydarkgrey);
  text-align: left;
}

.list,
.test-list-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.list {
  cursor: pointer;
  border: 0;
  padding: var(--padding-4xs) 0 var(--padding-2xs) var(--padding-lgi);
  background-color: var(--priism-secondaryltblue);
  width: 226px;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  gap: var(--gap-4xs);
  white-space: nowrap;
}

.list:hover {
  background-color: var(--color-lightsteelblue);
}

.test-list-container {
  align-self: stretch;
  flex: 1;
  padding: 0 var(--padding-8xs) 0 0;
}

.menu-item-2 {
  width: 177.2px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.research-card,
.title {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.title {
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap-13xl);
}

.research-card {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-mid);
}



.menu-item-3 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  flex-shrink: 0;
}

.another-menu-item,
.menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mid);
  box-sizing: border-box;
}

.menu-item {
  background-color: var(--priism-secondarywhite);
  border-right: 0.8px solid var(--priism-primaryltgrey);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-21xl) 0 var(--padding-538xl) var(--padding-8xs);
  gap: var(--gap-25xl);
  text-align: left;
  font-size: var(--headline-smallsb18-size);
  color: var(--priism-primaryltgrey);
  font-family: var(--headline-smallsb18);
  width: fit-content;
}


@media screen and (max-width: 825px) {
  .menu-item {
    display: none;
    padding-top: var(--padding-7xl);
    padding-bottom: var(--padding-343xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .research-module {
    font-size: var(--font-size-lgi);
    line-height: 26px;
  }

  .menu-item {
    gap: var(--gap-25xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-216xl);
    box-sizing: border-box;
  }
}

.css-1vr91td .MuiDrawer-paper {
  width: 68px !important;
  margin-left: -20px;
}

.css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper {
  width: 68px !important;
  margin-left: -20px;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  margin-top: -10px !important;
}

.body_box::-webkit-scrollbar {
  display: block;
}