@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --body-medium14: Inter;

    /* font sizes */
    --body-medium14-size: 0.88rem;
    --font-size-xs: 0.75rem;
    --display-small-36-size: 2.25rem;
    --font-size-3xl: 1.38rem;
    --font-size-10xl: 1.81rem;

    /* Colors */
    --color-gray-100: #fffffd;
    --color-gray-200: #878787;
    --color-gray-300: rgba(255, 255, 255, 0.9);
    --color-cornflowerblue: #1971c2;
    --color-darkslategray-100: #373737;
    --color-darkslategray-200: #323232;
    --color-black: #000;
    --color-red: #dc1205;
    --priism-primaryblue: #1971c2;

    /* Gaps */
    --gap-70xl: 5.56rem;
    --gap-xl: 1.25rem;
    --gap-base: 1rem;
    --gap-xs: 0.75rem;
    --gap-45xl: 4rem;
    --gap-20xl: 3rem;
    --gap-15xl: 2.5rem;
    --gap-13xl: 2rem;
    --gap-11xl: 1.88rem;

    /* Paddings */
    --padding-81xl: 6.25rem;
    --padding-25xl: 2.75rem;
    --padding-xs: 0.75rem;
    --padding-3xl: 1.38rem;
    --padding-23xl: 2.63rem;
    --padding-46xl: 4.06rem;
    --padding-xl: 1.25rem;
    --padding-5xl: 1.5rem;
    --padding-base: 1rem;
    --padding-13xl: 2rem;
    --padding-5xs: 0.5rem;
    --padding-9xs: 0.25rem;
    --padding-2xs: 0.69rem;

    /* Border radiuses */
    --br-xs: 12px;
    --br-81xl: 100px;
    --br-11xs: 2px;
    --br-9xs: 4px;
    --br-81xl: 100px;
    --body-largemed16: Roboto;
    --headline-smallsb18: Inter;
    --font-josefin-sans: "Josefin Sans";
    --br-base: 16px;
    /* font sizes */
    --body-largemed16-size: 16px;
    --headline-smallsb18-size: 18px;
    --title-largemed22-size: 22px;
    --headline-mediumsemibold24-size: 24px;
    --font-size-lgi: 19px;
    --label-large14-size: 14px;
    --font-size-13xl-1: 32.1px;
    --headline-large28-size: 1.75rem;

    /* Colors */
    --priism-secondarywhite: #fff;
    --priism-primaryltgrey: #878787;
    --priism-primarydarkgrey: #323232;
    --priism-secondaryltblue: #d6ebfe;
    --color-lightsteelblue: #bdd1e6;
    --color-cornflowerblue: #1971c2;

    /* Gaps */
    --gap-21xl: 40px;
    --gap-xl: 20px;
    --gap-17xl: 36px;
    --gap-base: 16px;
    --gap-sm: 14px;
    --gap-8xs: 5px;
    --gap-9xs: 4px;
    --gap-7xs: 6px;
    --gap-25xl: 44px;
    --gap-13xl: 32px;
    --gap-4xs: 9px;
    --gap-xs: 12px;

    /* Paddings */
    --padding-xl: 20px;
    --padding-21xl: 40px;
    --padding-5xl: 24px;
    --padding-lgi: 19px;
    --padding-10xl: 29px;
    --padding-mid: 17px;
    --padding-11xs: 2px;
    --padding-538xl: 557px;
    --padding-8xs: 5px;
    --padding-7xl: 26px;
    --padding-343xl: 362px;
    --padding-216xl: 235px;
    --padding-4xs: 9px;
    --padding-2xs: 11px;
    --padding-smi: 13px;
    --padding-34xl: 53px;
    --padding-15xl: 34px;
    --padding-30xl: 49px;

    /* Border radiuses */
    --br-5xs: 8px;

    /* fonts */
    --body-small12: Inter;
    --font-josefin-sans: "Josefin Sans";

    /* font sizes */
    --body-small12-size: 0.75rem;
    --display-small-36-size: 2.25rem;

    /* Colors */
    /* --priism-secondarywhite: #878787; */
    --priism-primarydarkgrey: #323232;

    /* Gaps */
    --gap-155xl: 10.88rem;
    --gap-327xl: 21.63rem;
    --gap-13xl: 2rem;
    --gap-xs: 0.75rem;

    /* Paddings */
    --padding-lg: 1.13rem;
    --padding-5xs: 0.5rem;
    --padding-9xs: 0.25rem;
}

.css-hyum1k-MuiToolbar-root {
    /* min-height: 100px !important; */
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 3px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 3px !important;
}

.delete-btn:hover {
    cursor: pointer;
}

.right-left-button-group {
    display: flex;
    width: 100%;
    justify-content: space-between !important;
}

.handsontable {
    display: block !important;
}