@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --body-medium14: Inter;

    /* font sizes */
    --body-medium14-size: 0.88rem;
    --font-size-xs: 0.75rem;
    --display-small-36-size: 2.25rem;
    --font-size-3xl: 1.38rem;
    --font-size-10xl: 1.81rem;

    /* Colors */
    --color-gray-100: #fffffd;
    --color-gray-200: #878787;
    --color-gray-300: rgba(255, 255, 255, 0.9);
    --color-cornflowerblue: #1971c2;
    --color-darkslategray-100: #373737;
    --color-darkslategray-200: #323232;
    --color-black: #000;
    --color-red: #dc1205;
    --priism-primaryblue: #1971c2;

    /* Gaps */
    --gap-70xl: 5.56rem;
    --gap-xl: 1.25rem;
    --gap-base: 1rem;
    --gap-xs: 0.75rem;
    --gap-45xl: 4rem;
    --gap-20xl: 3rem;
    --gap-15xl: 2.5rem;
    --gap-13xl: 2rem;
    --gap-11xl: 1.88rem;

    /* Paddings */
    --padding-81xl: 6.25rem;
    --padding-25xl: 2.75rem;
    --padding-xs: 0.75rem;
    --padding-3xl: 1.38rem;
    --padding-23xl: 2.63rem;
    --padding-46xl: 4.06rem;
    --padding-xl: 1.25rem;
    --padding-5xl: 1.5rem;
    --padding-base: 1rem;
    --padding-13xl: 2rem;
    --padding-5xs: 0.5rem;
    --padding-9xs: 0.25rem;
    --padding-2xs: 0.69rem;

    /* Border radiuses */
    --br-xs: 12px;
    --br-81xl: 100px;
    --br-11xs: 2px;
    --br-9xs: 4px;
    --br-81xl: 100px;
    --body-largemed16: Roboto;
    --headline-smallsb18: Inter;
    --font-josefin-sans: "Josefin Sans";
    --br-base: 16px;
    /* font sizes */
    --body-largemed16-size: 16px;
    --headline-smallsb18-size: 18px;
    --title-largemed22-size: 22px;
    --headline-mediumsemibold24-size: 24px;
    --font-size-lgi: 19px;
    --label-large14-size: 14px;
    --font-size-13xl-1: 32.1px;
    --headline-large28-size: 1.75rem;

    /* Colors */
    --priism-secondarywhite: #fff;
    --priism-primaryltgrey: #878787;
    --priism-primarydarkgrey: #323232;
    --priism-secondaryltblue: #d6ebfe;
    --color-lightsteelblue: #bdd1e6;
    --color-cornflowerblue: #1971c2;

    /* Gaps */
    --gap-21xl: 40px;
    --gap-xl: 20px;
    --gap-17xl: 36px;
    --gap-base: 16px;
    --gap-sm: 14px;
    --gap-8xs: 5px;
    --gap-9xs: 4px;
    --gap-7xs: 6px;
    --gap-25xl: 44px;
    --gap-13xl: 32px;
    --gap-4xs: 9px;
    --gap-xs: 12px;

    /* Paddings */
    --padding-xl: 20px;
    --padding-21xl: 40px;
    --padding-5xl: 24px;
    --padding-lgi: 19px;
    --padding-10xl: 29px;
    --padding-mid: 17px;
    --padding-11xs: 2px;
    --padding-538xl: 557px;
    --padding-8xs: 5px;
    --padding-7xl: 26px;
    --padding-343xl: 362px;
    --padding-216xl: 235px;
    --padding-4xs: 9px;
    --padding-2xs: 11px;
    --padding-smi: 13px;
    --padding-34xl: 53px;
    --padding-15xl: 34px;
    --padding-30xl: 49px;

    /* Border radiuses */
    --br-5xs: 8px;

    /* fonts */
    --body-small12: Inter;
    --font-josefin-sans: "Josefin Sans";

    /* font sizes */
    --body-small12-size: 0.75rem;
    --display-small-36-size: 2.25rem;

    /* Colors */
    /* --priism-secondarywhite: #878787; */
    --priism-primarydarkgrey: #323232;

    /* Gaps */
    --gap-155xl: 10.88rem;
    --gap-327xl: 21.63rem;
    --gap-13xl: 2rem;
    --gap-xs: 0.75rem;

    /* Paddings */
    --padding-lg: 1.13rem;
    --padding-5xs: 0.5rem;
    --padding-9xs: 0.25rem;
}

.css-hyum1k-MuiToolbar-root {
    /* min-height: 100px !important; */
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 3px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 3px !important;
}

.delete-btn:hover {
    cursor: pointer;
}

.right-left-button-group {
    display: flex;
    width: 100%;
    justify-content: space-between !important;
}

.handsontable {
    display: block !important;
}
.accordion-label {
    color: #fff !important;
    display: flex !important;
    justify-content: space-between !important;
    /*padding: 16px !important;*/
    background: rgba(4, 57, 94, 0.8) !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

/*.accordion-label:hover {
    background: rgba(4, 57, 94, 1) !important;
}*/
.selectedOption {
    color: #fff !important;
    display: flex !important;
    justify-content: space-between !important;
    background: rgba(4, 57, 94, 1) !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

.accordion-label::after {
    width: 6px !important;
    /*FFC82C*/
    height: 16px !important;
    text-align: center !important;
    transition: all 0.3s !important;
}

.button-colr {
    border-color: #ffc82c;
    background: #ffc82c;
    color: black;
}

.button-colr:hover {
    background: #ffc82c;
    color: #fff;
}

.accordion-content {
    color: #fff;
    padding: 0 16px !important;
    color: rgba(4, 57, 94, 1) !important;
    background: white !important;
    transition: all 0.3s !important;
}

.accordion-content p {
    color: #fff !important;
    margin: 0 !important;
    color: rgba(4, 57, 94, 0.7) !important;
    font-size: 18px !important;
}

.research_module .MuiAccordionSummary-root.Mui-expanded {
    background-color: #36607e !important;
}

fieldset {
    border-radius: 5px;
}

.radio-parent {
    color: midnightblue;
}

.question {
    color: rgba(4, 57, 94, 1) !important;
}

.ibutton {
    color: #ffc82c !important;
}

.next {
    float: right !important;
    background-color: #ffc82c !important;
}

.previous {
    background-color: #ffc82c !important;
}

.groupbtn {
    background-color: #969faa !important;
    border-color: #969faa !important;
}

.addbtn {
    background-color: #e1ebe6 !important;
    color: #000000 !important;
}

.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: transparent !important;
}

.subheading {
    color: #535252 !important;
    font-weight: 600 !important;
    font-size: 0.8rem;
}

.side-heading {
    color: #535252 !important;
    font-weight: 600 !important;
    font-size: 0.8rem;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: #d6eafe !important;
}

.th {
    background-color: #e1ebe6 !important;
}

.btncenter {
    left: 50% !important;
}

/* common */
.ribbon {
    overflow: hidden;
}

.ribbon::before,
.ribbon::after {
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #2980b9;
}

.definitions {
    /* color: black !important; */
    font-weight: 500 !important;
    /* color: rgba(4, 57, 94, 0.8) !important; */
    font-size: 0.79rem !important;
    padding: 5px !important;
}

.css-ecpxie-MuiTypography-root {
    line-height: 0 !important;
}

.text-center>div {
    text-align: center !important;
}

@media only screen and (max-width: 767px) {
    .css-1k93d8a {
        /* margin: auto !important;
        margin-left: 2% !important;
        margin-top: 10% !important;
        margin-right: 2% !important; */
        margin: 0px !important;
    }

    .question {
        font-weight: 500;
        font-size: 17px;
    }

    .accordion-label {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .selectedOption {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .definitions {
        text-align: justify;
        margin-right: 3%;
        font-size: 0.6rem !important;

    }

    .subheading {
        font-size: 14px !important;
    }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  /*display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-logo-paused {
  animation-play-state: paused;
}

.Mui-selected {
  background-color: floralwhite !important;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
  background-color: yellow !important;
  font-size: 20px !important;
}

.MuiButtonGroup-grouped {
  text-transform: none !important;
}

td.custom-cell {
  color: #fff;
  background-color: #37bc6c;
}

.custom-table thead th:nth-child(even) {
  background-color: #ffe8e8;
}

.custom-table tbody td:nth-child(even) {
  background-color: #f9f3f3;
}

.custom-table thead th:nth-child(odd) {
  background-color: #d6eafe;
}

.custom-table tbody td:nth-child(odd) {
  background-color: #e5f4fc;
}

.listItemsCard {
  padding: 0px !important;
}

.checkbox-no-margin {
  min-width: 0px !important;
}

.cardAccent {
  border: 5px solid #36617e;
}

.logo-frame {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--priism-primaryltgrey);
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px var(--padding-xl);
  z-index: 250;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  text-align: right;
}

.summitlogo-icon {
  width: 123px;
  height: 37.6px;
  position: relative;
  object-fit: cover;

}


body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --body-largemed16: Roboto;
  --headline-smallsb18: Inter;
  --font-josefin-sans: "Josefin Sans";

  /* font sizes */
  --body-largemed16-size: 16px;
  --label-large14-size: 14px;
  --headline-smallsb18-size: 18px;
  --title-largemed22-size: 22px;
  --headline-mediumsemibold24-size: 24px;
  --body-small12-size: 12px;

  /* Colors */
  --priism-secondarywhite: #fff;
  --color-silver: #bcbcbc;
  --color-dimgray: #535252;
  --priism-primaryblue: #1971c2;
  --priism-primaryltgrey: #878787;
  --color-gray-100: #262626;
  --priism-primarydarkgrey: #323232;
  --priism-errorred: red;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-5xs: 8px;
}
.sign-in {
    margin: 0;
    position: relative;
    font-size: inherit;
    line-height: 2.75rem;
    font-weight: 400;
    font-family: inherit;
}

.signintxt,
.topheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.signintxt {
    flex: 1 1;
    padding: var(--padding-5xs);
    box-sizing: border-box;
}

.topheader {
    align-self: stretch;
    font-size: var(--display-small-36-size);
}

.enter-your-username {
    flex: 1 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.enteryourusernameoremailaddres {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.priisminputfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.username {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    grid-gap: var(--gap-xs);
    gap: var(--gap-xs);
    max-width: 100%;
}

.enter-your-password {
    flex: 1 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.password {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.passwordfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.container {
    margin: 0;
    height: 1.13rem;
    width: 1.13rem;
    position: relative;
    border-radius: var(--br-11xs);
    border: 2px solid var(--priism-primarydarkgrey);
    box-sizing: border-box;
}

.checkboxes,
.state-layer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.state-layer {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--br-81xl);
    flex-direction: row;
    padding: var(--padding-2xs);
    box-sizing: border-box;
}

.checkboxes {
    flex-direction: column;
}

.label {
    color: var(--priism-primaryltgrey);
}

.forgot-password {
    color: var(--color-red);
    text-decoration: none;
    cursor: pointer;
}

.logo-frame {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: var(--gap-xl);
    gap: var(--gap-xl);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.inputfields,
.passwordinputfiled {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
}

.passwordinputfiled {
    align-items: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    grid-gap: var(--gap-xs);
    gap: var(--gap-xs);
}

.inputfields {
    align-items: flex-end;
    grid-gap: var(--gap-11xl);
    gap: var(--gap-11xl);
}

.signinmain-child {
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.signinbutton {
    align-self: stretch;
    height: 3rem;
}

.span {
    color: var(--color-darkslategray-100);
}

.sign-up {
    color: var(--color-cornflowerblue);
}

.dont-have-an-container {
    width: 16.13rem;
    position: relative;
    line-height: 1.25rem;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.noaccount,
.signinmain {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}

.signinmain {
    background: linear-gradient(rgba(255, 248, 248, 0.2),
            rgba(255, 248, 248, 0.2)),
        linear-gradient(#fff, #fff), rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 35px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
    grid-gap: var(--gap-xl);
    gap: var(--gap-xl);
    mix-blend-mode: normal;
    /* min-height: 46.13rem; */
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--priism-primarydarkgrey);
    font-family: var(--body-small12);
}

.project_desc {
    height: 60vh;
    font-style: italic;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.6);
    font-family: var(--body-largemed16-size);
    scrollbar-width: none;
    opacity: 0;
    /* Initially hide the container */
    animation: fadeIn 2s ease forwards;
    /* Animation for fading in */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        /* Start with opacity 0 */
    }

    to {
        opacity: 1;
        /* End with opacity 1 */
    }
}

.project_desc:hover {
    scroll-behavior: smooth;
    scrollbar-width: thin;
}

/* .project_desc::-webkit-scrollbar {
    display: block;
} */

@media screen and (max-width: 1050px) {
    .sign-in {
        font-size: var(--font-size-10xl);
        line-height: 2.19rem;
    }
}

@media screen and (max-width: 750px) {
    .topheader {
        grid-gap: var(--gap-45xl);
        gap: var(--gap-45xl);
    }

    .signinmain {
        grid-gap: var(--gap-xl);
        gap: var(--gap-xl);
    }
}

@media screen and (max-width: 450px) {
    .sign-in {
        font-size: var(--font-size-3xl);
        line-height: 1.63rem;
    }

    .topheader {
        grid-gap: var(--gap-45xl);
        gap: var(--gap-45xl);
    }

    .logo-frame {
        flex-wrap: wrap;
    }
}
.username1 {
    flex: 1 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.email-address {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.priisminputfield,
.username {
    align-self: stretch;
    font-family: var(--body-medium14);
}

.priisminputfield {
    border: 0;
    background-color: transparent;
    height: 2.5rem;
    font-size: var(--font-size-xs);
    color: var(--color-gray-100);
}

.username {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    grid-gap: var(--gap-xs);
    gap: var(--gap-xs);
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--color-black);
}
.leftgrfx-icon {
    height: 100vh !important;
    flex: 1 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    min-width: 31.25rem;
    min-height: 100vh !important;
}

.mobile_logo_frame {
    display: none;
}

.logo-1-icon {
    width: 7.56rem;
    height: 2.31rem;
    position: relative;
    object-fit: cover;
}

.top-header-frame {
    align-self: stretch;
    display: inline-block;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    /* padding: var(--padding-5xl); */
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.privacy-text{
    position: absolute;
    bottom: 0px;
    left: 50%;
}

.signin-main {
    flex: 0.8687 1;
    flex-direction: column;
    /* padding: 100px 44px var(--padding-xs); */
    height: 100vh;
    background: antiquewhite;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 15%;
    box-sizing: border-box;
    grid-gap: 8vh;
    gap: 8vh;
    min-width: 500px;
    max-width: 100%;
}

.key_feature_section {
    padding: 3%;
}

.left-graphic-frame,
.sign-indesktop,
.signin-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.left-graphic-frame {
    align-self: stretch;
    flex-direction: row;
    grid-row-gap: 20px;
    row-gap: 20px;
    max-width: 100%;
}

.sign-indesktop {
    width: 100%;
    position: relative;
    background-color: #fffffd;
    overflow: hidden;
    flex-direction: column;
    letter-spacing: normal;
}

@media screen and (max-width: 1125px) {

    .leftgrfx-icon,
    .signin-main {
        flex: 1 1;
    }

    .left-graphic-frame {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1050px) {
    .signin-main {
        padding-top: 4.06rem;
        padding-bottom: 1.25rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 750px) {

    .leftgrfx-icon,
    .signin-main {
        min-width: 100%;
    }

    .signin-main {
        /* gap: var(--gap-70xl); */
        padding-left: var(--padding-3xl);
        padding-right: var(--padding-3xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 950px) {
    .leftgrfx-icon {
        display: none;
    }

    .mobile_logo_frame {
        display: block;
        width: 100%;
        object-fit: contain;
    }

    .sign-indesktop {
        height: 100%;
    }

    .css-0 {
        height: inherit !important;
    }

    .signin-main {
        /* gap: var(--gap-70xl); */
        padding-top: 2.63rem;
        box-sizing: border-box;
    }
}
.research-title,
.retinal-vasculature-and,
.test-type {
  position: absolute;
  top: 55px;
  left: 17px;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  width: 80%;
}

.retinal-vasculature-and,
.test-type {
  top: 92px;
}

.retinal-vasculature-and {
  top: 54px;
  font-size: var(--body-largemed16-size);
  font-weight: 500;
  font-family: var(--headline-smallsb18);
  width: 349px;
}

.frame-inner,
.frame-item {
  position: absolute;
  top: 24px;
  right: 21px;
  width: 40px;
  height: 40px;
}

.frame-inner {
  top: 80px;
  right: 19px;
}

.button-text1 {
  position: relative;
  font-weight: 600;
}

.mainbuttons {
  position: absolute !important;
  top: 200px !important;
  left: 17px !important;
  border-radius: var(--br-5xs) !important;
  background-color: var(--priism-primaryblue) !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: var(--padding-5xs) var(--padding-5xl) !important;
  box-sizing: border-box !important;
  font-size: var(--label-large14-size) !important;
  color: var(--priism-secondarywhite) !important;
}

.group-icon {
  width: 16px;
  position: relative;
  height: 16px;
}

.cross-sectional {
  /* width: 119px; */
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}

.group-parent {
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-gap: var(--gap-9xs);
  gap: var(--gap-9xs);
}

.frame-wrapper {
  position: absolute;
  top: 121px;
  left: 17px;
  width: 139px;
  height: 24px;
  font-size: var(--body-largemed16-size);
  font-family: var(--headline-smallsb18);
}

.research-title-label {
  left: 17px;
  top: 24px;
  position: absolute;
  color: var(--priism-primaryblue);
  font-weight: bold;
}

.research-type-label {
  top: 20px;
  position: absolute;
  color: var(--priism-primaryblue);
  font-weight: bold;
}

.research-title-parent {
  flex: 1 1;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--priism-secondarywhite);
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
  height: 255px;
  overflow: hidden;
  /* min-width: 460px; */
  /* max-width: 535px; */
  text-align: left;
  /* font-size: var(--headline-smallsb18-size); */
  color: var(--priism-primarydarkgrey);
  font-family: var(--headline-smallsb18);
}
.my-list-of {
  width: 497.1px;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 28px;
  font-weight: bold;
  display: inline-block;
  font-size: large;
  font-family: "Inter" !important;
}

.testlist-cards {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 36px;
  gap: 36px;
}

.my-list-of-test-research-parent {
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-family: "Inter" !important;
  /* padding: 40px 32px 36px; */
  /* gap: 20px; */
  /* padding-bottom: 10% !important; */
}

/* .my-list-of-test-research-parent {} */

.right-content-space {
  background-image: linear-gradient(to right, transparent, white);
}
.variable-name-retinal-artery-parent {
    flex: 1 1;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: var(--card-elevation);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xl) var(--padding-mini);
    box-sizing: border-box;
    grid-gap: var(--gap-base);
    gap: var(--gap-base);
    min-width: 365px;
    max-width: 400px;
}

.unassigned-variables {
    width: 181px;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    text-align: left;
    color: rgba(3, 3, 3, 0.651);
}

.variable-name-retinal-artery-container {
    align-self: stretch;
    position: relative;
    line-height: 24px;
}

.secondaryoutlinedbuttons-wrapper {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    font-size: var(--m3-body-medium-size);
    color: var(--priism-primaryblue);
    font-family: var(--display-small-36);
}

.variable-name {
    font-weight: 500;
}

.span1 {
    font-size: var(--headline-smallsb18-size);
    font-weight: 600;
    font-family: var(--display-small-36);
}

.secondaryoutlinedbuttons {
    border-radius: var(--br-5xs);
    border: 1px solid var(--priism-primaryltgrey);
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-xs) var(--padding-5xs) 0px;
}

.icontrashplain {
    width: 40px;
    border-radius: var(--br-81xl);
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
}

.state-layer43 {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}


.icon10 {
    position: relative;
    width: 24px;
    height: 24px;
}

.button-text6 {
    position: relative;
    font-weight: 600;
}

.testlist-cards3 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: var(--gap-13xl);
    gap: var(--gap-13xl);
    font-family: var(--m3-body-medium);
}
.text-fieldwithicon {
    width: 210px;
    border-radius: var(--br-9xs) var(--br-9xs) 0px 0px;
    height: 56px;
}
.group-item,
.priism2 {
    position: absolute;
    top: 0;
}

.priism2 {
    left: 0;
    letter-spacing: -0.02em;
}

.group-item {
    left: 86px;
    width: 18.3px;
    height: 12.4px;
}

.logo1,
.priism-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 32px;
}

.logo1 {
    top: 34px;
    left: 49px;
}

.profilephoto-icon1 {
    width: 48px;
    position: relative;
    border-radius: 50%;
    height: 48px;
    object-fit: cover;
}

.researcher1,
.vivek-shastri1 {
    width: 106px;
    position: relative;
    display: inline-block;
}

.vivek-shastri1 {
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
}

.researcher1 {
    font-size: var(--body-small12-size);
    line-height: 18px;
}

.profieltext1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.arrow-icon1 {
    width: 12px;
    position: relative;
    height: 6px;
}

.profilegrp1 {
    position: absolute;
    top: 17px;
    right: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: var(--gap-xs);
    gap: var(--gap-xs);
    font-size: var(--body-medium14-size);
    font-family: var(--display-small-36);
}

.logoheader1 {
    align-self: stretch;
    position: relative;
    background: radial-gradient(50% 50%at 50% 50%, #d13a3b, #0a365f);
    height: 100px;
    overflow: hidden;
    flex-shrink: 0;
}

.frame-child2 {
    position: absolute;
    top: 129px;
    left: 22.2px;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondaryltblue);
    width: 226px;
    height: 44px;
}

.menu-item-21,
.menu-item-31,
.study-list1 {
    position: absolute;
    left: 75.8px;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 177.2px;
}

.study-list1 {
    top: 138.3px;
    color: var(--priism-primarydarkgrey);
}

.menu-item-21,
.menu-item-31 {
    top: 205.9px;
}

.menu-item-31 {
    top: 273.5px;
}

.list-icon1 {
    position: absolute;
    top: 138.3px;
    left: 42px;
    width: 24.6px;
    height: 24.6px;
    overflow: hidden;
}

.research-module1 {
    position: absolute;
    top: 40px;
    left: 32px;
    font-size: var(--headline-mediumsemibold24-size);
    line-height: 32px;
    font-weight: 600;
    color: var(--priism-primaryblue);
    display: inline-block;
    width: 238px;
}

.frame-parent4 {
    align-self: stretch;
    width: 270px;
    position: relative;
    background-color: var(--priism-secondarywhite);
    border-right: 0.8px solid var(--priism-secondarydisabled);
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
}

.vector-icon2 {
    width: 7px;
    position: relative;
    height: 14px;
    object-fit: contain;
}

.navarrow1 {
    width: 40px;
    border-radius: var(--br-81xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-2xs);
    box-sizing: border-box;
}

.back1 {
    width: 45px;
    position: relative;
    letter-spacing: -0.02em;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.backbtn1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--priism-primaryblue);
}

.statistical-test-variables1 {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 500;
}

.all-unassigned-variables {
    align-self: stretch;
    position: relative;
    font-size: var(--body-largemed16-size);
    line-height: 24px;
    font-weight: 500;
    font-family: var(--body-largemed16);
    color: var(--priism-primaryblue);
    display: inline-block;
    height: 77px;
    flex-shrink: 0;
}

.statistical-test-variables-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: var(--gap-9xs);
    gap: var(--gap-9xs);
    max-width: 900px;
    font-size: var(--title-largemed22-size);
}

.unassigned-variables {
    width: 181px;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
}

.label-text5 {
    position: relative;
    line-height: 16px;
    font-weight: 600;
}

.draggablechip,
.label-text-wrapper,
.state-layer7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.state-layer7 {
    padding: var(--padding-7xs) var(--padding-xs);
}

.draggablechip {
    width: 124.8px;
    border-radius: var(--br-base);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
    border: 0.8px solid var(--priism-primaryltgrey);
    box-sizing: border-box;
    height: 32.8px;
    overflow: hidden;
    margin: 5px;
    flex-shrink: 0;
}

.draggablechip:hover {
    cursor: grab;
}

.draggalechips,
.unassignedvar {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.draggalechips {
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: var(--gap-base);
    gap: var(--gap-base);
    text-align: center;
    font-size: var(--body-smallsb12-size);
    font-family: var(--display-small-36);
    height: 6vh;
}

.unassignedvar {
    flex-direction: column;
    grid-gap: 9px;
    gap: 9px;
    z-index: 0;
}

.blueframe {
    width: 290px;
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    border-right: 1px solid var(--priism-primaryblue);
    border-bottom: 1px solid var(--priism-primaryblue);
    border-left: 1px solid var(--priism-primaryblue);
    box-sizing: border-box;
    min-height: 168px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.grouping-variables {
    width: 290px;
    position: relative;
    line-height: 18px;
    display: inline-block;
    margin-top: 4px;
}

.groupvar,
.var-buckets {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.groupvar {
    flex-direction: column;
    grid-gap: var(--gap-5xs);
    gap: var(--gap-5xs);
}

.var-buckets {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 48px;
    gap: 48px;
    z-index: 1;
    text-align: center;
    font-size: var(--body-medium14-size);
    font-family: var(--display-small-36);
}

.grab-svgrepo-com-2-icon {
    width: 39px;
    position: absolute;
    margin: 0 !important;
    top: 33px;
    left: 384px;
    height: 39px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.dragdrop,
.unassignedvar-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.unassignedvar-parent {
    position: relative;
    grid-gap: 50px;
    gap: 50px;
}

.dragdrop {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 26px var(--padding-13xl);
    font-family: var(--body-largemed16);
    color: var(--priism-primarydarkgrey);
}

.iconplus1 {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button-text3 {
    position: relative;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
}

.primaryfilledbuttons1 {
    border-radius: var(--br-5xs);
    background-color: var(--priism-primaryblue);
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-5xl);
    box-sizing: border-box;
    grid-gap: var(--gap-5xs);
    gap: var(--gap-5xs);
}

.primaryfilledbuttons-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-base) var(--padding-5xl);
    font-size: var(--body-medium14-size);
    color: var(--priism-secondarywhite);
}

.backbtn-group,
.frame-parent3,
.logoheader-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.backbtn-group {
    flex: 1 1;
    flex-direction: column;
    padding: var(--padding-21xl) 0;
    grid-gap: var(--gap-base);
    gap: var(--gap-base);
    font-size: var(--body-largemed16-size);
    color: var(--priism-primarydarkgrey);
}

.frame-parent3,
.logoheader-group {
    align-self: stretch;
}

.frame-parent3 {
    flex-direction: row;
    padding: 0 var(--padding-5xl);
    grid-gap: var(--gap-17xl);
    gap: var(--gap-17xl);
    font-size: var(--headline-smallsb18-size);
    color: var(--priism-primaryltgrey);
    font-family: var(--display-small-36);
}

.logoheader-group {
    flex-direction: column;
}

.summitlogo-icon {
    width: 123px;
    position: relative;
    height: 37.6px;
    object-fit: cover;
}

.summitlogo-wrapper {
    align-self: stretch;
    border-top: 1px solid var(--priism-secondarydisabled);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-base) 20px;
}

.statistical-test-variables {
    width: 1398px;
    background-color: var(--priism-secondarywhite);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-size-13xl-1);
    color: var(--priism-secondarywhite);
    font-family: var(--font-josefin-sans);
}
.text-field-instance {
    height: 14px;
    width: 7px;
    position: relative;
    object-fit: contain;
}

.back {
    flex: 1 1;
    position: relative;
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-top: -3px;
}

.backbtn {
    width: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.what-is-the {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 500;
}

.a-brief-description {
    width: 100%;
    position: relative;
    font-size: var(--body-largemed16-size);
    line-height: 24px;
    font-weight: 500;
    font-family: var(--body-largemed16);
    color: var(--priism-primaryblue);
    display: inline-block;
    max-width: 100%;
}

.content-frame,
.state-layer1,
.text-field1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content-frame {
    align-self: stretch;
    flex-direction: column;
    grid-gap: 4px;
    gap: 4px;
    max-width: 100%;
    font-size: var(--title-largemed22-size);
    color: var(--priism-primarydarkgrey);
}

.state-layer1,
.text-field1 {
    box-sizing: border-box;
}

.state-layer1 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    align-self: stretch;
    height: 56px;
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-base);
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-primarydarkgrey);
    min-width: 250px;
}

.text-field1 {
    width: 450px;
    border-radius: var(--br-9xs);
    /* border: 1px solid var(--priism-primaryltgrey); */
    flex-direction: column;
}

.supporting-text1 {
    width: 178px;
    position: relative;
    line-height: 18px;
    display: none;
}

.supporting-text,
.text-field {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.supporting-text {
    width: 100%;
    height: 20px;
    margin: 0 !important;
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0;
    box-sizing: border-box;
}

.text-field {
    /* width: 450px; */
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-shrink: 0;
    flex-direction: column;
    position: relative;
    margin-bottom: -20px;
    padding-bottom: var(--padding-xl);
}

.titleofstudy-inputfields,
.text-field-wrapper,
.username {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.titleofstudy-inputfields,
.username {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
}

.titleofstudy-inputfields {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: var(--padding-5xl);
    font-size: var(--body-small12-size);
    color: var(--priism-primaryltgrey);
    margin-top: 1%;
}

.iconplus {
    height: 18px;
    width: 18px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button-text2 {
    position: relative;
    font-size: var(--label-large14-size);
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
    font-family: var(--body-small12);
    color: var(--priism-secondarywhite);
    text-align: left;
}

.primaryfilledbuttons,
.primaryoutlinedbuttons,
.primaryplainbuttons {
    cursor: pointer;
    border: 0;
    padding: var(--padding-5xs);
    background-color: var(--priism-primaryblue);
    height: 40px;
    border-radius: var(--br-5xs);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    grid-gap: var(--gap-5xs);
    gap: var(--gap-5xs);
}

.primaryfilledbuttons {
    background-color: var(--priism-primaryblue) !important;
}

.primaryfilledbuttons,
.primaryoutlinedbuttons,
.primaryplainbuttons {
    border-radius: var(--br-5xs) !important;
    color: var(--priism-secondarywhite) !important;
    height: 40px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    padding: var(--padding-5xs) !important;
    box-sizing: border-box !important;
    font-size: var(--label-large14-size) !important;
}

.primaryoutlinedbuttons,
.primaryplainbuttons {
    color: var(--priism-primaryblue) !important;
}

.primaryoutlinedbuttons {
    border: 1px solid var(--priism-primaryblue) !important;
    grid-gap: 16px;
    gap: 16px;
}

.back-button-frame {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-base) var(--padding-5xs);
    grid-gap: 16px;
    gap: 16px;
}

.title-frame {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
}

.title-frame {
    flex: 1 1;
    flex-direction: column;
    /*padding: var(--padding-21xl) 0;
    gap: 16px;*/
    position: relative;
    top: 25px;
    height: 90%;
    max-width: 100%;
    font-size: var(--body-largemed16-size);
    color: var(--priism-primaryblue);
    margin: 0% 3%;
    margin-bottom: 100px;
}

@media screen and (max-width: 825px) {
    .title-frame {
        max-width: 100%;
    }
}

@media screen and (max-width: 675px) {
    .titleofstudy-inputfields {
        grid-gap: var(--gap-13xl);
        gap: var(--gap-13xl);
    }
}

@media screen and (max-width: 450px) {
    .what-is-the {
        font-size: var(--headline-smallsb18-size);
        line-height: 22px;
    }

    .text-field {
        max-width: 100%;
    }

    .title-frame {
        padding-top: var(--padding-7xl);
        padding-bottom: var(--padding-7xl);
        box-sizing: border-box;
    }
}
.study_type_radio-parent,
.study_type_testlist-cards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.study_type_testlist-cards {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2vh;
    color: var(--priism-primarydarkgrey);
}

.study_type_radio-parent {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondaryltblue);
    border: 1px solid var(--priism-primaryblue);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-base) 22px;
    position: relative;
}

.study_type_radio {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 370px;
    position: relative;
    height: 48px;
    z-index: 0;
}
.asset-1-1 {
    width: 100px;
    position: relative;
    height: 249.2px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}

.forgot-password1,
.not-to-worry {
    align-self: stretch;
    position: relative;
}

.forgot-password1 {
    font-size: var(--headline-large28-size);
    line-height: 36px;
}

.not-to-worry {
    line-height: 18px;
}

.input-text7 {
    width: 39px;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: none;
}

.caret-icon3 {
    width: 1px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 16px;
}

.input-text6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
}

.label-text44 {
    position: relative;
    line-height: 16px;
}

.label-text43 {
    margin: 0 !important;
    position: absolute;
    top: -12px;
    left: -4px;
    background-color: var(--m3-sys-light-surface);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-9xs);
    z-index: 1;
    font-size: var(--body-small12-size);
    color: var(--priism-primaryblue);
}

.content31,
.state-layer51,
.text-field4 {
    flex: 1 1;
    display: flex;
    align-items: flex-start;
}

.content31 {
    height: 48px;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    position: relative;
}

.state-layer51,
.text-field4 {
    align-self: stretch;
    justify-content: flex-start;
}

.state-layer51 {
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-direction: row;
    padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
}

.text-field4 {
    border-radius: var(--br-9xs);
    border: 3px solid var(--priism-primaryblue);
    flex-direction: column;
    z-index: 0;
}

.supporting-text37 {
    width: 178px;
    position: relative;
    line-height: 18px;
    display: none;
}

.supporting-text36,
.text-field3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.supporting-text36 {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    height: 20px;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0;
    box-sizing: border-box;
    z-index: 1;
    font-size: var(--body-small12-size);
    color: var(--priism-primaryltgrey);
    font-family: var(--display-small-36);
}

.text-field3 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    height: 48px;
    flex-direction: column;
    position: relative;
    font-size: var(--body-largemed16-size);
    color: var(--m3-sys-light-on-surface);
    font-family: var(--m3-body-medium);
}

.button-text11 {
    position: relative;
    font-weight: 600;
}

.mainbuttons1 {
    border-radius: var(--br-5xs);
    background-color: var(--priism-primaryblue);
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-5xl) !important;
    box-sizing: border-box;
    color: var(--priism-secondarywhite);
    margin-top: 3vh !important;
}

.forgot-password-parent {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    grid-gap: 20px 0;
    gap: 20px 0;
}

.asset-1-1-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    grid-gap: 56px;
    gap: 56px;
}

.logo-1-icon2 {
    width: 85px;
    position: relative;
    height: 26px;
    object-fit: cover;
}

.logo-1-frame {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-5xs);
}

.forgotpassword,
.forgotpassword-inner {
    display: flex;
    justify-content: flex-start;
}

.forgotpassword-inner {
    align-self: stretch;
    height: 47px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

.forgotpassword {
    width: 100%;
    max-width: 547px;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-5xl);
    box-sizing: border-box;
    grid-gap: 8px 0;
    gap: 8px 0;
    text-align: left;
    font-size: var(--m3-body-medium-size);
    color: var(--color-black);
    font-family: var(--display-small-36);
    margin: auto;
    position: relative;
    top: 20vh;
}
.enter-your-password {
    flex: 1 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.alreadyhavean-account {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
    flex-shrink: 0;
}

.passwordfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-medium14);
    font-size: var(--font-size-xs);
    color: var(--color-gray-100);
}

.button-text,
.usernameoremailaddress {
    align-self: stretch;
    height: 1.25rem;
}

.button-text {
    height: 4.94rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    grid-gap: var(--gap-xs);
    gap: var(--gap-xs);
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--color-black);
    font-family: var(--body-medium14);
}
.sign-up {
    margin: 0;
    position: relative;
    font-size: var(--display-small-36-size);
    line-height: 2.75rem;
    font-weight: 400;
    font-family: var(--body-medium14);
    color: var(--color-black);
    text-align: left;
    white-space: nowrap;
}

.inputfields,
.signintxt,
.signuptxt {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.signintxt {
    flex: 1 1;
    padding: var(--padding-5xs);
    box-sizing: border-box;
}

.inputfields,
.signuptxt {
    align-self: stretch;
}

.inputfields {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: var(--gap-base);
    gap: var(--gap-base);
}

.mainbuttons {
    align-self: stretch;
    height: 2.5rem;
}

.already-have-an {
    color: var(--color-darkslategray);
}

.sign-in {
    color: var(--color-cornflowerblue);
}

.already-have-an-container {
    width: 16.13rem;
    position: relative;
    font-size: var(--body-medium14-size);
    line-height: 1.13rem;
    font-family: var(--body-medium14);
    text-align: left;
    display: inline-block;
    flex-shrink: 0;
}

.noaccount,
.signupbox {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}

.signupbox {
    margin: 0;
    border-radius: var(--br-xs);
    background-color: #fffffd;
    box-shadow: 0 4px 35px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
    grid-gap: var(--gap-xl);
    gap: var(--gap-xl);
    min-height: 46.13rem;
    max-width: 100%;
}

@media screen and (max-width: 1050px) {
    .sign-up {
        font-size: var(--font-size-10xl);
        line-height: 2.19rem;
    }
}

@media screen and (max-width: 750px) {
    .signuptxt {
        grid-gap: var(--gap-45xl);
        gap: var(--gap-45xl);
    }
}

@media screen and (max-width: 450px) {
    .sign-up {
        font-size: var(--font-size-3xl);
        line-height: 1.63rem;
    }

    .signuptxt {
        grid-gap: var(--gap-45xl);
        gap: var(--gap-45xl);
    }
}
.signup-leftgrfx-icon {
    min-height: 100% !important;
    height: 100% !important;
}

.mobile_logo_frame {
    display: none;
}

.logo-1-icon {
    width: 7.56rem;
    height: 2.31rem;
    position: relative;
    object-fit: cover;
}

.logo-frame {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 1.5rem;
}

.sign-up-box {
    flex: 0.8778 1;
    flex-direction: column;
    padding: 6.25rem 2.75rem 0.75rem;
    box-sizing: border-box;
    grid-gap: var(--gap-70xl);
    gap: var(--gap-70xl);
    min-width: 31.25rem;
    max-width: 100%;
}

.frame-left-graphic,
.sign-up-box,
.sign-up-desktop {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.frame-left-graphic {
    align-self: stretch;
    flex-direction: row;
    grid-row-gap: 20px;
    row-gap: 20px;
    max-width: 100%;
}

.sign-up-desktop {
    width: 100%;
    position: relative;
    background-color: #fffffd;
    overflow: hidden;
    flex-direction: column;
    letter-spacing: normal;
}

@media screen and (max-width: 1125px) {

    .leftgrfx-icon,
    .sign-up-box {
        flex: 1 1;
    }

    .frame-left-graphic {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1050px) {
    .sign-up-box {
        padding-top: 4.06rem;
        padding-bottom: 1.25rem;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 750px) {

    .leftgrfx-icon,
    .sign-up-box {
        min-width: 100%;
    }

    .sign-up-box {
        grid-gap: var(--gap-70xl);
        gap: var(--gap-70xl);
        padding-left: var(--padding-3xl);
        padding-right: var(--padding-3xl);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 900px) {
    .leftgrfx-icon {
        display: none;
    }

    .mobile_logo_frame {
        display: block;
        width: 100%;
        object-fit: contain;
    }

    .sign-up-desktop {
        height: 100%;
    }

    .css-0 {
        height: inherit !important;
    }

    .sign-up-box {
        grid-gap: var(--gap-70xl);
        gap: var(--gap-70xl);
        padding-top: 2.63rem;
        box-sizing: border-box;
    }
}

.logo-1-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-5xl);
}

.logo-1-icon2 {
    width: 121px;
    position: absolute;
    bottom: 25px;
    height: 37px;
    object-fit: cover;
}
.priism {
  position: relative;
  letter-spacing: -0.02em;
  white-space: nowrap;
}

.frame-child {
  height: 13.4px;
  width: 21.3px;
  position: relative;
  z-index: 1;
  margin-left: -25px;
  top: -10px;
}

.priism-parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.profilephoto-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
}

.researcher,
.vivek-shastri {
  align-self: stretch;
  position: relative;
}

.vivek-shastri {
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.researcher {
  height: 16px;
}

.text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.arrow-icon {
  height: 6px;
  width: 12px;
  position: relative;
}

.logoheader,
.profile-card {
  display: flex;
  flex-direction: row;
}

.profile-card {
  width: 300px;
  align-items: center;
  justify-content: flex-start;
  grid-gap: var(--gap-xs);
  gap: var(--gap-xs);
  font-size: var(--label-large14-size);
  font-family: var(--headline-smallsb18);
  cursor: pointer;
}

.logoheader {
  align-self: stretch;
  /* background: linear-gradient(to right, #433753 0%, 44.54314708709717%, #853848 89.08629417419434%, 94.54314708709717%, #363756 100%); */
  background: linear-gradient(to right, rgba(67, 55, 83, 1) 0%, 44.54314708709717%, rgba(133, 56, 72, 1) 89.08629417419434%, 94.54314708709717%, rgba(54, 55, 86, 1) 100%);
  ;
  overflow: hidden;
  /* align-items: flex-end; */
  justify-content: space-between;
  padding: 0px 15px 0px 5px;
  grid-gap: var(--gap-xl);
  gap: var(--gap-xl);
  top: 0;
  z-index: 99;
  position: -webkit-sticky;
  position: sticky;
  text-align: left;
  font-size: var(--font-size-13xl-1);
  color: var(--priism-secondarywhite);
  font-family: var(--font-josefin-sans);
}

.menuitem {
  padding: 10px 14px !important;
}

.logout_menu {
  color: var(--priism-primaryblue) !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  width: 100% !important;
}

@media screen and (max-width: 675px) {
  .logoheader {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-7xl);
    box-sizing: border-box;
  }
}

.animate-negative-points {
  width: 25px;
  height: 25px;
  background-color: orange;
  font-weight: bold;
  position: relative;
  animation: negative-points;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes negative-points {
  from {background-color: red;}
  to {background-color: orange;}
}

.animate-positive-points {
  width: auto;
  height: auto;
  background-color: green;
  font-weight: bold;
  position: relative;
  animation: positive-points;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
}

@keyframes positive-points {
  from {background-color: lightgreen;}
  to {background-color: green;}
}
.research-module {
  flex: 1 1;
  position: relative;
  line-height: 32px;
  font-weight: 600;
}

.study-list-section {
  width: 238px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-smi);
  box-sizing: border-box;
  font-size: var(--headline-mediumsemibold24-size);
  color: var(--color-cornflowerblue);
}

.list-icon,
.study-list {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
}

.list-icon {
  height: 24.6px;
  width: 24.6px;
  overflow: hidden;
}

.study-list {
  flex: 1 1;
  font-size: var(--headline-smallsb18-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--headline-smallsb18);
  color: var(--priism-primarydarkgrey);
  text-align: left;
}

.list,
.test-list-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.list {
  cursor: pointer;
  border: 0;
  padding: var(--padding-4xs) 0 var(--padding-2xs) var(--padding-lgi);
  background-color: var(--priism-secondaryltblue);
  width: 226px;
  border-radius: var(--br-5xs);
  box-sizing: border-box;
  grid-gap: var(--gap-4xs);
  gap: var(--gap-4xs);
  white-space: nowrap;
}

.list:hover {
  background-color: var(--color-lightsteelblue);
}

.test-list-container {
  align-self: stretch;
  flex: 1 1;
  padding: 0 var(--padding-8xs) 0 0;
}

.menu-item-2 {
  width: 177.2px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.research-card,
.title {
  align-self: stretch;
  flex: 1 1;
  display: flex;
  justify-content: flex-start;
}

.title {
  flex-direction: column;
  align-items: flex-end;
  grid-gap: var(--gap-13xl);
  gap: var(--gap-13xl);
}

.research-card {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 var(--padding-mid);
}



.menu-item-3 {
  flex: 1 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  flex-shrink: 0;
}

.another-menu-item,
.menu-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-mid);
  box-sizing: border-box;
}

.menu-item {
  background-color: var(--priism-secondarywhite);
  border-right: 0.8px solid var(--priism-primaryltgrey);
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-end;
  padding: var(--padding-21xl) 0 var(--padding-538xl) var(--padding-8xs);
  grid-gap: var(--gap-25xl);
  gap: var(--gap-25xl);
  text-align: left;
  font-size: var(--headline-smallsb18-size);
  color: var(--priism-primaryltgrey);
  font-family: var(--headline-smallsb18);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}


@media screen and (max-width: 825px) {
  .menu-item {
    display: none;
    padding-top: var(--padding-7xl);
    padding-bottom: var(--padding-343xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .research-module {
    font-size: var(--font-size-lgi);
    line-height: 26px;
  }

  .menu-item {
    grid-gap: var(--gap-25xl);
    gap: var(--gap-25xl);
    padding-top: var(--padding-xl);
    padding-bottom: var(--padding-216xl);
    box-sizing: border-box;
  }
}

.css-1vr91td .MuiDrawer-paper {
  width: 68px !important;
  margin-left: -20px;
}

.css-aq1gdx-MuiDrawer-docked .MuiDrawer-paper {
  width: 68px !important;
  margin-left: -20px;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  margin-top: -10px !important;
}

.body_box::-webkit-scrollbar {
  display: block;
}
.blue-bordered-layout {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    border: 1px solid var(--priism-primaryblue);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base);
    max-width: 100%;
    font-size: var(--label-large14-size);
    font-family: var(--title-largemed22);
}

.full-width {
    width: 100% !important;
}

.white-card-layout-with-shadow {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-xl);
    box-sizing: border-box;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    font-size: var(--body-small12-size);
    color: var(--color-black);
    margin-top: 1%;
}

.sub-head-level-1 {
    margin: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    font-size: var(--headline-smallsb18-size);
    line-height: 24px;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    max-width: 100%;
    color: var(--priism-primarydarkgrey);
}

.checkboxes {
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px var(--padding-xs) 0px var(--padding-2xs);
    box-sizing: border-box;
    max-width: 100%;
    text-align: left;
    font-size: var(--title-largemed22-size);
    color: var(--priism-primarydarkgrey);
    font-family: var(--title-largemed22);
}

.content_section {
    width: 60%;
}

.mainContent {
    display: flex;
    justify-content: center;
    width: 100%;
}

.cross_sectional_longitudinal_section {
    display: flex;
    align-items: center;
    padding: 0;
}

.card-row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: stretch;
    grid-gap: var(--gap-11xl);
    gap: var(--gap-11xl);
    max-width: 100%;
    font-size: var(--body-small12-size);
    font-family: var(--title-largemed22);
}

.desc_info_icon {
    cursor: pointer;
    color: #ff8800;
}

.optional_box_container {
    display: flex !important;
    flex-direction: row !important;
    border: 1px solid var(--priism-primaryblue);
    padding: 2%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    justify-content: center;
    /* margin-left: 5%;
    margin-right: 5%; */
    margin: auto;
}

.optional_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 345px;
    height: 205px;
    margin: 1%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10%;
    text-align: left;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.optional_box_selected {
    border-color: #D04D8C;
    cursor: pointer;
}

.optional_box_img {
    width: 85px;
    height: 84px;
    margin: 5%;
}
