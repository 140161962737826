.study_type_radio-parent,
.study_type_testlist-cards {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.study_type_testlist-cards {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2vh;
    color: var(--priism-primarydarkgrey);
}

.study_type_radio-parent {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondaryltblue);
    border: 1px solid var(--priism-primaryblue);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-base) 22px;
    position: relative;
}

.study_type_radio {
    width: fit-content;
    min-width: 370px;
    position: relative;
    height: 48px;
    z-index: 0;
}