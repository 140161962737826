.accordion-label {
    color: #fff !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
    /*padding: 16px !important;*/
    background: rgba(4, 57, 94, 0.8) !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

/*.accordion-label:hover {
    background: rgba(4, 57, 94, 1) !important;
}*/
.selectedOption {
    color: #fff !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    justify-content: space-between !important;
    background: rgba(4, 57, 94, 1) !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

.accordion-label::after {
    width: 6px !important;
    /*FFC82C*/
    height: 16px !important;
    text-align: center !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
}

.button-colr {
    border-color: #ffc82c;
    background: #ffc82c;
    color: black;
}

.button-colr:hover {
    background: #ffc82c;
    color: #fff;
}

.accordion-content {
    color: #fff;
    padding: 0 16px !important;
    color: rgba(4, 57, 94, 1) !important;
    background: white !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
}

.accordion-content p {
    color: #fff !important;
    margin: 0 !important;
    color: rgba(4, 57, 94, 0.7) !important;
    font-size: 18px !important;
}

.research_module .MuiAccordionSummary-root.Mui-expanded {
    background-color: #36607e !important;
}

fieldset {
    border-radius: 5px;
}

.radio-parent {
    color: midnightblue;
}

.question {
    color: rgba(4, 57, 94, 1) !important;
}

.ibutton {
    color: #ffc82c !important;
}

.next {
    float: right !important;
    background-color: #ffc82c !important;
}

.previous {
    background-color: #ffc82c !important;
}

.groupbtn {
    background-color: #969faa !important;
    border-color: #969faa !important;
}

.addbtn {
    background-color: #e1ebe6 !important;
    color: #000000 !important;
}

.css-zqcytf-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: transparent !important;
}

.subheading {
    color: #535252 !important;
    font-weight: 600 !important;
    font-size: 0.8rem;
}

.side-heading {
    color: #535252 !important;
    font-weight: 600 !important;
    font-size: 0.8rem;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
    background-color: #d6eafe !important;
}

.th {
    background-color: #e1ebe6 !important;
}

.btncenter {
    left: 50% !important;
}

/* common */
.ribbon {
    overflow: hidden;
}

.ribbon::before,
.ribbon::after {
    z-index: -1;
    content: "";
    display: block;
    border: 5px solid #2980b9;
}

.definitions {
    /* color: black !important; */
    font-weight: 500 !important;
    /* color: rgba(4, 57, 94, 0.8) !important; */
    font-size: 0.79rem !important;
    padding: 5px !important;
}

.css-ecpxie-MuiTypography-root {
    line-height: 0 !important;
}

.text-center>div {
    text-align: center !important;
}

@media only screen and (max-width: 767px) {
    .css-1k93d8a {
        /* margin: auto !important;
        margin-left: 2% !important;
        margin-top: 10% !important;
        margin-right: 2% !important; */
        margin: 0px !important;
    }

    .question {
        font-weight: 500;
        font-size: 17px;
    }

    .accordion-label {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .selectedOption {
        font-size: 15px !important;
        font-weight: 500 !important;
    }

    .definitions {
        text-align: justify;
        margin-right: 3%;
        font-size: 0.6rem !important;

    }

    .subheading {
        font-size: 14px !important;
    }
}