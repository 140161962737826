.group-item,
.priism2 {
    position: absolute;
    top: 0;
}

.priism2 {
    left: 0;
    letter-spacing: -0.02em;
}

.group-item {
    left: 86px;
    width: 18.3px;
    height: 12.4px;
}

.logo1,
.priism-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    height: 32px;
}

.logo1 {
    top: 34px;
    left: 49px;
}

.profilephoto-icon1 {
    width: 48px;
    position: relative;
    border-radius: 50%;
    height: 48px;
    object-fit: cover;
}

.researcher1,
.vivek-shastri1 {
    width: 106px;
    position: relative;
    display: inline-block;
}

.vivek-shastri1 {
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
}

.researcher1 {
    font-size: var(--body-small12-size);
    line-height: 18px;
}

.profieltext1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.arrow-icon1 {
    width: 12px;
    position: relative;
    height: 6px;
}

.profilegrp1 {
    position: absolute;
    top: 17px;
    right: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
    font-size: var(--body-medium14-size);
    font-family: var(--display-small-36);
}

.logoheader1 {
    align-self: stretch;
    position: relative;
    background: radial-gradient(50% 50%at 50% 50%, #d13a3b, #0a365f);
    height: 100px;
    overflow: hidden;
    flex-shrink: 0;
}

.frame-child2 {
    position: absolute;
    top: 129px;
    left: 22.2px;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondaryltblue);
    width: 226px;
    height: 44px;
}

.menu-item-21,
.menu-item-31,
.study-list1 {
    position: absolute;
    left: 75.8px;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 177.2px;
}

.study-list1 {
    top: 138.3px;
    color: var(--priism-primarydarkgrey);
}

.menu-item-21,
.menu-item-31 {
    top: 205.9px;
}

.menu-item-31 {
    top: 273.5px;
}

.list-icon1 {
    position: absolute;
    top: 138.3px;
    left: 42px;
    width: 24.6px;
    height: 24.6px;
    overflow: hidden;
}

.research-module1 {
    position: absolute;
    top: 40px;
    left: 32px;
    font-size: var(--headline-mediumsemibold24-size);
    line-height: 32px;
    font-weight: 600;
    color: var(--priism-primaryblue);
    display: inline-block;
    width: 238px;
}

.frame-parent4 {
    align-self: stretch;
    width: 270px;
    position: relative;
    background-color: var(--priism-secondarywhite);
    border-right: 0.8px solid var(--priism-secondarydisabled);
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
}

.vector-icon2 {
    width: 7px;
    position: relative;
    height: 14px;
    object-fit: contain;
}

.navarrow1 {
    width: 40px;
    border-radius: var(--br-81xl);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-2xs);
    box-sizing: border-box;
}

.back1 {
    width: 45px;
    position: relative;
    letter-spacing: -0.02em;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.backbtn1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--priism-primaryblue);
}

.statistical-test-variables1 {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 28px;
    font-weight: 500;
}

.all-unassigned-variables {
    align-self: stretch;
    position: relative;
    font-size: var(--body-largemed16-size);
    line-height: 24px;
    font-weight: 500;
    font-family: var(--body-largemed16);
    color: var(--priism-primaryblue);
    display: inline-block;
    height: 77px;
    flex-shrink: 0;
}

.statistical-test-variables-parent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    max-width: 900px;
    font-size: var(--title-largemed22-size);
}

.unassigned-variables {
    width: 181px;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
}

.label-text5 {
    position: relative;
    line-height: 16px;
    font-weight: 600;
}

.draggablechip,
.label-text-wrapper,
.state-layer7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.state-layer7 {
    padding: var(--padding-7xs) var(--padding-xs);
}

.draggablechip {
    width: 124.8px;
    border-radius: var(--br-base);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
    border: 0.8px solid var(--priism-primaryltgrey);
    box-sizing: border-box;
    height: 32.8px;
    overflow: hidden;
    margin: 5px;
    flex-shrink: 0;
}

.draggablechip:hover {
    cursor: grab;
}

.draggalechips,
.unassignedvar {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.draggalechips {
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-base);
    text-align: center;
    font-size: var(--body-smallsb12-size);
    font-family: var(--display-small-36);
    height: 6vh;
}

.unassignedvar {
    flex-direction: column;
    gap: 9px;
    z-index: 0;
}

.blueframe {
    width: 290px;
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    border-right: 1px solid var(--priism-primaryblue);
    border-bottom: 1px solid var(--priism-primaryblue);
    border-left: 1px solid var(--priism-primaryblue);
    box-sizing: border-box;
    min-height: 168px;
    overflow: hidden;
    flex-shrink: 0;
    text-align: left;
    height: fit-content;
}

.grouping-variables {
    width: 290px;
    position: relative;
    line-height: 18px;
    display: inline-block;
    margin-top: 4px;
}

.groupvar,
.var-buckets {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.groupvar {
    flex-direction: column;
    gap: var(--gap-5xs);
}

.var-buckets {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
    z-index: 1;
    text-align: center;
    font-size: var(--body-medium14-size);
    font-family: var(--display-small-36);
}

.grab-svgrepo-com-2-icon {
    width: 39px;
    position: absolute;
    margin: 0 !important;
    top: 33px;
    left: 384px;
    height: 39px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.dragdrop,
.unassignedvar-parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.unassignedvar-parent {
    position: relative;
    gap: 50px;
}

.dragdrop {
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    padding: 26px var(--padding-13xl);
    font-family: var(--body-largemed16);
    color: var(--priism-primarydarkgrey);
}

.iconplus1 {
    width: 18px;
    position: relative;
    height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button-text3 {
    position: relative;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 600;
}

.primaryfilledbuttons1 {
    border-radius: var(--br-5xs);
    background-color: var(--priism-primaryblue);
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-5xl);
    box-sizing: border-box;
    gap: var(--gap-5xs);
}

.primaryfilledbuttons-container {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: var(--padding-base) var(--padding-5xl);
    font-size: var(--body-medium14-size);
    color: var(--priism-secondarywhite);
}

.backbtn-group,
.frame-parent3,
.logoheader-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.backbtn-group {
    flex: 1;
    flex-direction: column;
    padding: var(--padding-21xl) 0;
    gap: var(--gap-base);
    font-size: var(--body-largemed16-size);
    color: var(--priism-primarydarkgrey);
}

.frame-parent3,
.logoheader-group {
    align-self: stretch;
}

.frame-parent3 {
    flex-direction: row;
    padding: 0 var(--padding-5xl);
    gap: var(--gap-17xl);
    font-size: var(--headline-smallsb18-size);
    color: var(--priism-primaryltgrey);
    font-family: var(--display-small-36);
}

.logoheader-group {
    flex-direction: column;
}

.summitlogo-icon {
    width: 123px;
    position: relative;
    height: 37.6px;
    object-fit: cover;
}

.summitlogo-wrapper {
    align-self: stretch;
    border-top: 1px solid var(--priism-secondarydisabled);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-base) 20px;
}

.statistical-test-variables {
    width: 1398px;
    background-color: var(--priism-secondarywhite);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--font-size-13xl-1);
    color: var(--priism-secondarywhite);
    font-family: var(--font-josefin-sans);
}