.research-title,
.retinal-vasculature-and,
.test-type {
  position: absolute;
  top: 55px;
  left: 17px;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  width: 80%;
}

.retinal-vasculature-and,
.test-type {
  top: 92px;
}

.retinal-vasculature-and {
  top: 54px;
  font-size: var(--body-largemed16-size);
  font-weight: 500;
  font-family: var(--headline-smallsb18);
  width: 349px;
}

.frame-inner,
.frame-item {
  position: absolute;
  top: 24px;
  right: 21px;
  width: 40px;
  height: 40px;
}

.frame-inner {
  top: 80px;
  right: 19px;
}

.button-text1 {
  position: relative;
  font-weight: 600;
}

.mainbuttons {
  position: absolute !important;
  top: 200px !important;
  left: 17px !important;
  border-radius: var(--br-5xs) !important;
  background-color: var(--priism-primaryblue) !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: var(--padding-5xs) var(--padding-5xl) !important;
  box-sizing: border-box !important;
  font-size: var(--label-large14-size) !important;
  color: var(--priism-secondarywhite) !important;
}

.group-icon {
  width: 16px;
  position: relative;
  height: 16px;
}

.cross-sectional {
  /* width: 119px; */
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  flex-shrink: 0;
}

.group-parent {
  position: absolute;
  top: 45px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.frame-wrapper {
  position: absolute;
  top: 121px;
  left: 17px;
  width: 139px;
  height: 24px;
  font-size: var(--body-largemed16-size);
  font-family: var(--headline-smallsb18);
}

.research-title-label {
  left: 17px;
  top: 24px;
  position: absolute;
  color: var(--priism-primaryblue);
  font-weight: bold;
}

.research-type-label {
  top: 20px;
  position: absolute;
  color: var(--priism-primaryblue);
  font-weight: bold;
}

.research-title-parent {
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--priism-secondarywhite);
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
  height: 255px;
  overflow: hidden;
  /* min-width: 460px; */
  /* max-width: 535px; */
  text-align: left;
  /* font-size: var(--headline-smallsb18-size); */
  color: var(--priism-primarydarkgrey);
  font-family: var(--headline-smallsb18);
}