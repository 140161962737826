.sign-in {
    margin: 0;
    position: relative;
    font-size: inherit;
    line-height: 2.75rem;
    font-weight: 400;
    font-family: inherit;
}

.signintxt,
.topheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.signintxt {
    flex: 1;
    padding: var(--padding-5xs);
    box-sizing: border-box;
}

.topheader {
    align-self: stretch;
    font-size: var(--display-small-36-size);
}

.enter-your-username {
    flex: 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.enteryourusernameoremailaddres {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.priisminputfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.username {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    gap: var(--gap-xs);
    max-width: 100%;
}

.enter-your-password {
    flex: 1;
    position: relative;
    line-height: 1.13rem;
    display: inline-block;
    max-width: 100%;
}

.password {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
}

.passwordfield {
    border: 0;
    background-color: transparent;
    align-self: stretch;
    height: 2.5rem;
    font-family: var(--body-small12);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.container {
    margin: 0;
    height: 1.13rem;
    width: 1.13rem;
    position: relative;
    border-radius: var(--br-11xs);
    border: 2px solid var(--priism-primarydarkgrey);
    box-sizing: border-box;
}

.checkboxes,
.state-layer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.state-layer {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--br-81xl);
    flex-direction: row;
    padding: var(--padding-2xs);
    box-sizing: border-box;
}

.checkboxes {
    flex-direction: column;
}

.label {
    color: var(--priism-primaryltgrey);
}

.forgot-password {
    color: var(--color-red);
    text-decoration: none;
    cursor: pointer;
}

.logo-frame {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-xl);
    font-size: var(--body-small12-size);
    color: var(--priism-secondarywhite);
}

.inputfields,
.passwordinputfiled {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
}

.passwordinputfiled {
    align-items: flex-start;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    gap: var(--gap-xs);
}

.inputfields {
    align-items: flex-end;
    gap: var(--gap-11xl);
}

.signinmain-child {
    width: 6.25rem;
    height: 6.25rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.signinbutton {
    align-self: stretch;
    height: 3rem;
}

.span {
    color: var(--color-darkslategray-100);
}

.sign-up {
    color: var(--color-cornflowerblue);
}

.dont-have-an-container {
    width: 16.13rem;
    position: relative;
    line-height: 1.25rem;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.noaccount,
.signinmain {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs);
}

.signinmain {
    background: linear-gradient(rgba(255, 248, 248, 0.2),
            rgba(255, 248, 248, 0.2)),
        linear-gradient(#fff, #fff), rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 35px rgba(0, 0, 0, 0.12);
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-base) var(--padding-13xl);
    box-sizing: border-box;
    gap: var(--gap-xl);
    mix-blend-mode: normal;
    /* min-height: 46.13rem; */
    max-width: 100%;
    text-align: left;
    font-size: var(--body-medium14-size);
    color: var(--priism-primarydarkgrey);
    font-family: var(--body-small12);
}

.project_desc {
    height: 60vh;
    font-style: italic;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.6);
    font-family: var(--body-largemed16-size);
    scrollbar-width: none;
    opacity: 0;
    /* Initially hide the container */
    animation: fadeIn 2s ease forwards;
    /* Animation for fading in */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        /* Start with opacity 0 */
    }

    to {
        opacity: 1;
        /* End with opacity 1 */
    }
}

.project_desc:hover {
    scroll-behavior: smooth;
    scrollbar-width: thin;
}

/* .project_desc::-webkit-scrollbar {
    display: block;
} */

@media screen and (max-width: 1050px) {
    .sign-in {
        font-size: var(--font-size-10xl);
        line-height: 2.19rem;
    }
}

@media screen and (max-width: 750px) {
    .topheader {
        gap: var(--gap-45xl);
    }

    .signinmain {
        gap: var(--gap-xl);
    }
}

@media screen and (max-width: 450px) {
    .sign-in {
        font-size: var(--font-size-3xl);
        line-height: 1.63rem;
    }

    .topheader {
        gap: var(--gap-45xl);
    }

    .logo-frame {
        flex-wrap: wrap;
    }
}