.asset-1-1 {
    width: 100px;
    position: relative;
    height: 249.2px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: contain;
}

.forgot-password1,
.not-to-worry {
    align-self: stretch;
    position: relative;
}

.forgot-password1 {
    font-size: var(--headline-large28-size);
    line-height: 36px;
}

.not-to-worry {
    line-height: 18px;
}

.input-text7 {
    width: 39px;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: none;
}

.caret-icon3 {
    width: 1px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 16px;
}

.input-text6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    z-index: 0;
}

.label-text44 {
    position: relative;
    line-height: 16px;
}

.label-text43 {
    margin: 0 !important;
    position: absolute;
    top: -12px;
    left: -4px;
    background-color: var(--m3-sys-light-surface);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-9xs);
    z-index: 1;
    font-size: var(--body-small12-size);
    color: var(--priism-primaryblue);
}

.content31,
.state-layer51,
.text-field4 {
    flex: 1;
    display: flex;
    align-items: flex-start;
}

.content31 {
    height: 48px;
    flex-direction: column;
    justify-content: center;
    padding: var(--padding-9xs) 0;
    box-sizing: border-box;
    position: relative;
}

.state-layer51,
.text-field4 {
    align-self: stretch;
    justify-content: flex-start;
}

.state-layer51 {
    border-radius: var(--br-9xs) var(--br-9xs) 0 0;
    flex-direction: row;
    padding: var(--padding-9xs) 0 var(--padding-9xs) var(--padding-base);
}

.text-field4 {
    border-radius: var(--br-9xs);
    border: 3px solid var(--priism-primaryblue);
    flex-direction: column;
    z-index: 0;
}

.supporting-text37 {
    width: 178px;
    position: relative;
    line-height: 18px;
    display: none;
}

.supporting-text36,
.text-field3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.supporting-text36 {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    right: 0;
    bottom: -20px;
    left: 0;
    height: 20px;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-base) 0;
    box-sizing: border-box;
    z-index: 1;
    font-size: var(--body-small12-size);
    color: var(--priism-primaryltgrey);
    font-family: var(--display-small-36);
}

.text-field3 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    height: 48px;
    flex-direction: column;
    position: relative;
    font-size: var(--body-largemed16-size);
    color: var(--m3-sys-light-on-surface);
    font-family: var(--m3-body-medium);
}

.button-text11 {
    position: relative;
    font-weight: 600;
}

.mainbuttons1 {
    border-radius: var(--br-5xs);
    background-color: var(--priism-primaryblue);
    height: fit-content !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-5xs) var(--padding-5xl) !important;
    box-sizing: border-box;
    color: var(--priism-secondarywhite);
    margin-top: 3vh !important;
}

.forgot-password-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    gap: 20px 0;
}

.asset-1-1-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
}

.logo-1-icon2 {
    width: 85px;
    position: relative;
    height: 26px;
    object-fit: cover;
}

.logo-1-frame {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: var(--padding-5xs);
}

.forgotpassword,
.forgotpassword-inner {
    display: flex;
    justify-content: flex-start;
}

.forgotpassword-inner {
    align-self: stretch;
    height: 47px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

.forgotpassword {
    width: 100%;
    max-width: 547px;
    border-radius: var(--br-5xs);
    background-color: var(--priism-secondarywhite);
    box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-5xl);
    box-sizing: border-box;
    gap: 8px 0;
    text-align: left;
    font-size: var(--m3-body-medium-size);
    color: var(--color-black);
    font-family: var(--display-small-36);
    margin: auto;
    position: relative;
    top: 20vh;
}