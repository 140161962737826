@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  /*display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-logo-paused {
  animation-play-state: paused;
}

.Mui-selected {
  background-color: floralwhite !important;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
  background-color: yellow !important;
  font-size: 20px !important;
}

.MuiButtonGroup-grouped {
  text-transform: none !important;
}

td.custom-cell {
  color: #fff;
  background-color: #37bc6c;
}

.custom-table thead th:nth-child(even) {
  background-color: #ffe8e8;
}

.custom-table tbody td:nth-child(even) {
  background-color: #f9f3f3;
}

.custom-table thead th:nth-child(odd) {
  background-color: #d6eafe;
}

.custom-table tbody td:nth-child(odd) {
  background-color: #e5f4fc;
}

.listItemsCard {
  padding: 0px !important;
}

.checkbox-no-margin {
  min-width: 0px !important;
}

.cardAccent {
  border: 5px solid #36617e;
}

.logo-frame {
  width: 100%;
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--priism-primaryltgrey);
  align-items: flex-end;
  justify-content: flex-end;
  padding: 16px var(--padding-xl);
  z-index: 250;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  text-align: right;
}

.summitlogo-icon {
  width: 123px;
  height: 37.6px;
  position: relative;
  object-fit: cover;

}


body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --body-largemed16: Roboto;
  --headline-smallsb18: Inter;
  --font-josefin-sans: "Josefin Sans";

  /* font sizes */
  --body-largemed16-size: 16px;
  --label-large14-size: 14px;
  --headline-smallsb18-size: 18px;
  --title-largemed22-size: 22px;
  --headline-mediumsemibold24-size: 24px;
  --body-small12-size: 12px;

  /* Colors */
  --priism-secondarywhite: #fff;
  --color-silver: #bcbcbc;
  --color-dimgray: #535252;
  --priism-primaryblue: #1971c2;
  --priism-primaryltgrey: #878787;
  --color-gray-100: #262626;
  --priism-primarydarkgrey: #323232;
  --priism-errorred: red;

  /* Paddings */
  --padding-5xs: 8px;
  --padding-5xl: 24px;

  /* Border radiuses */
  --br-5xs: 8px;
}